import { createAsyncThunk, createAction, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch";

const SET_LOADED_FORM = 'SET_LOADED_FORM'
const ADD_SECTION = 'ADD_SECTION'
const REMOVE_SECTION = "REMOVE_SECTION"
const SAVE_ITEM_TO_FIELD = "SAVE_ITEM_TO_FIELD"
const ADD_FIELD_TO_SECTION = "ADD_FIELD_TO_SECTION"
const REMOVE_FIELD_FROM_SECTION = "REMOVE_FIELD_FROM_SECTION"
const SET_INCLUDE_PAR = "SET_INCLUDE_PAR"
const SET_USER_RESPONSE_PROMPT = "SET_USER_RESPONSE_PROMPT"
const SET_ACTION = "SET_ACTION"
const SET_PAR_VALUE = "SET_PAR_VALUE"
const SET_CHECKLIST_DESCRIPTION = "SET_CHECKLIST_DESCRIPTION"
const SET_CHECKLIST_ADMIN_RESPONSE = "SET_CHECKLIST_ADMIN_RESPONSE"
const SET_VISUAL_FILE_UPLOAD = "SET_VISUAL_FILE_UPLOAD"
const SET_VISUAL_DESCRIPTION = "SET_VISUAL_DESCRIPTION"
const SET_RESPONSE_DESCRIPTION = "SET_RESPONSE_DESCRIPTION"
const SET_D_ROWS = "SET_D_ROWS"
const CLEAR_D_ROWS = "CLEAR_D_ROWS"
const ADD_USER_RESPONSE = "ADD_USER_RESPONSE"
const DELETE_USER_RESPONSE = "DELETE_USER_RESPONSE"

const SET_CHECKLIST_TRUE_TEXT = "SET_CHECKLIST_TRUE_TEXT"
const SET_CHECKLIST_FALSE_TEXT = "SET_CHECKLIST_FALSE_TEXT"

const TOGGLE_REPORT_DELETION = "TOGGLE_REPORT_DELETION"
const SET_FORM_NAME = "SET_FORM_NAME"


const SET_JOB_POSITION = 'SET_JOB_POSITION'
const SET_ASSIGNED_USER = 'SET_ASSIGNED_USER'
const SET_DESTINATION_FORMAT = 'SET_DESTINATION_FORMAT'
const SET_FREQUENCY = 'SET_FREQUENCY'
const SET_TASK_NAME = 'SET_TASK_NAME'
const SET_FORM_FIELD = 'SET_FORM_FIELD'
const SET_JOB_REPORT = "SET_JOB_REPORT"
const SET_USER_REPORT = "SET_USER_REPORT"

const REMOVE_COLUMN = "REMOVE_COLUMN"
const ADD_COLUMN = "ADD_COLUMN"
const TOGGLE_ITEM_TO_ADD = "TOGGLE_ITEM_TO_ADD"
const UPDATE_PROMPT = "UPDATE_PROMPT"
const UPDATE_ASSIGNED_JOB_POSITION = "UPDATE_ASSIGNED_JOB_POSITION"
const SET_ANSWER_CHOICES = "SET_ANSWER_CHOICES"
const ADD_ANSWER_CHOICE = "ADD_ANSWER_CHOICE"
const REMOVE_ANSWER_CHOICE = "REMOVE_ANSWER_CHOICE"
const ADD_RES_COL = "ADD_RES_COL"
const SET_RES_COL = "SET_RES_COL"
const REMOVE_RES_COL = "REMOVE_RES_COL"
const TOGGLE_PAR = "TOGGLE_PAR"
const SET_TRACKED_STATUS = "SET_TRACKED_STATUS"
const ADD_REPORT_ROW = "ADD_REPORT_ROW"
const SET_DISPLAY = "SET_DISPLAY"

const SET_DUE_DATE = "SET_DUE_DATE"
const DELETE_SELECTED_ROWS = "DELETE_SELECTED_ROWS"
const SET_START_DATE = "SET_START_DATE"

const SET_SCHEDULE = "SET_SCHEDULE"
const SET_END_DATE = "SET_END_DATE"
const SET_INTERVAL_TYPE = "SET_INTERVAL_TYPE"
const SET_INTERVAL_VALUE = "SET_INTERVAL_VALUE"
const SET_DAY_VALUE_OF_MONTH = "SET_DAY_VALUE_OF_MONTH"
const SET_MONTH_VALUE_OF_MONTH = "SET_MONTH_VALUE_OF_MONTH"
const SET_ORDINAL_MONTH_VALUE = "SET_ORDINAL_MONTH_VALUE"
const SET_MONTH_VALUE_FOR_YEAR = "SET_MONTH_VALUE_FOR_YEAR"
const SET_ORDINAL_VALUE_YEAR = "SET_ORDINAL_VALUE_YEAR"
const SET_MONTH_VALUE_YEAR_2 = "SET_MONTH_VALUE_YEAR_2"
const SET_MONTH_OPTION = "SET_MONTH_OPTION"
const SET_YEAR_OPTION = "SET_YEAR_OPTION"

const SET_DAY_FOR_YEAR = "SET_DAY_FOR_YEAR"

const SET_INTERVAL_AMOUNT = "SET_INTERVAL_AMOUNT"
const TOGGLE_ALL_PURCHASE_TRIGGERS = "TOGGLE_ALL_PURCHASE_TRIGGERS"

const SET_G_EMAIL = "SET_G_EMAIL'"
const SET_TRIGGER_PURCHASE = "SET_TRIGGER_PURCHASE"
const SET_DESTINATION_TYPE = "SET_DESTINATION_TYPE"
const SET_G_PASSWORD = "SET_G_PASSWORD"
const SET_REQUESTED_REPORT = "SET_REQUESTED_REPORT"
const CHANGE_SECTION_NAME = 'Forms/changeSectionName';
const EDIT_ASSIGNED_JOB_POSITION = 'EDIT_ASSIGNED_JOB_POSITION'
const SET_ASSIGNED_USER_EDIT = "SET_ASSIGNED_USER_EDIT"
const SET_REPORTING_ASSIGNED_USER = "SET_REPORTING_ASSIGNED_USER"
const UPDATE_ASSIGNED_REPORTING_JOB_POSITION = "UPDATE_ASSIGNED_REPORTING_JOB_POSITION"


export const updateFormSections = createAction('Forms/updateFormSections');

export const reorderFields = createAction('Forms/reorderFields');

export const AddSection = createAsyncThunk(
    'Forms/setSection',
    async (item) => {
        return {
            type: ADD_SECTION,
            payload: item
        }
    }
);

export const setDisplayOfReport = createAsyncThunk(
    'Forms/setDisplayOfReport',
    async (item) => {
        return {
            type: SET_DISPLAY,
            payload: item
        }
    }
);


export const setFrequency = createAsyncThunk(
    'Forms/setFrequency',
    async (item) => {
        return {
            type: SET_FREQUENCY,
            payload: item
        }
    }
);

export const RemoveSection = createAsyncThunk(
    'Forms/removeSection',
    async (item) => {
        return {
            type: REMOVE_SECTION,
            payload: item
        }
    }
);

export const settingJobToReportTo = createAsyncThunk(
    'Forms/settingJobToReportTo',
    async (item) => {
        return {
            type: SET_JOB_REPORT,
            payload: item
        }
    }
);

export const addFieldToSection = createAsyncThunk(
    'Forms/addFieldToSection',
    async (item) => {
        return {
            type: ADD_FIELD_TO_SECTION,
            payload: item
        }
    }
);

export const removeFieldFromSection = createAsyncThunk(
    'Forms/removeFieldFromSection',
    async (item) => {
        return {
            type: REMOVE_FIELD_FROM_SECTION,
            payload: item
        }
    }
);

export const SaveItemToField = createAsyncThunk(
    'Forms/SaveItemToField',
    async (data) => {
        console.log("Recieved data :", data)
        return {
            type: SAVE_ITEM_TO_FIELD,
            payload: data
        }

    }
);

export const SetForm = createAsyncThunk(
    'Forms/setForm',
    async (item) => {
        return {
            type: SET_LOADED_FORM,
            payload: item
        }
    }
);

export const setIncludePar = createAsyncThunk(
    'Forms/setIncludePar',
    async (item) => {
        return {
            type: SET_INCLUDE_PAR,
            payload: item
        }
    }
);

export const setTriggerPurchase = createAsyncThunk(
    'Forms/setTriggerPurchase',
    async (item) => {
        return {
            type: SET_TRIGGER_PURCHASE,
            payload: item
        }
    }
);





export const setParValue = createAsyncThunk(
    'Forms/setParValue',
    async (item) => {
        return {
            type: SET_PAR_VALUE,
            payload: item
        }
    }
);


export const setUserResponsePrompt = createAsyncThunk(
    'Forms/setUserResponsePrompt',
    async (item) => {
        return {
            type: SET_USER_RESPONSE_PROMPT,
            payload: item
        }
    }
);

export const setAction = createAsyncThunk(
    'Forms/setAction',
    async (item) => {
        return {
            type: SET_ACTION,
            payload: item
        }
    }
);


export const setVisualDescription = createAsyncThunk(
    'Forms/setVisualDescription',
    async (item) => {
        return {
            type: SET_VISUAL_DESCRIPTION,
            payload: item
        }
    }
)

export const setChecklistDescription = createAsyncThunk(
    'Forms/setChecklistDescription',
    async (item) => {
        return {
            type: SET_CHECKLIST_DESCRIPTION,
            payload: item
        }
    }
);

export const setResponseDescription = createAsyncThunk(
    'Forms/setResponseDescription',
    async (item) => {
        return {
            type: SET_RESPONSE_DESCRIPTION,
            payload: item
        }
    }
);



export const setDestinationType = createAsyncThunk(
    'Forms/setDestinationType',
    async (item) => {
        return {
            type: SET_DESTINATION_TYPE,
            payload: item
        }
    }
);

export const setGoogleEmail = createAsyncThunk(
    'Forms/setGoogleEmail',
    async (item) => {
        return {
            type: SET_G_EMAIL,
            payload: item
        }
    }
);

export const setGooglePassword = createAsyncThunk(
    'Forms/setGooglePassword',
    async (item) => {
        return {
            type: SET_G_PASSWORD,
            payload: item
        }
    }
);





export const setChecklistAdminResponse = createAsyncThunk(
    'Forms/setChecklistAdminResponse',
    async (item) => {
        return {
            type: SET_CHECKLIST_ADMIN_RESPONSE,
            payload: item
        }
    }
);


export const setDestinationFormat = createAsyncThunk(
    'Forms/setDestinationFormat',
    async (item) => {
        return {
            type: SET_DESTINATION_FORMAT,
            payload: item
        }
    }
);


export const saveForm = createAsyncThunk(
    'Forms/saveForm',
    async (item) => {
        return {
            type: SET_LOADED_FORM,
            payload: item
        }
    }
);

export const setFormForEdit = createAsyncThunk(
    'Forms/setFormForEdit',
    async (item) => {
        return {
            type: SET_LOADED_FORM,
            payload: item
        }
    }
);

export const setVisualFileUpload = createAsyncThunk(
    'Forms/setVisualFileUpload',
    async (item) => {
        return {
            type: SET_VISUAL_FILE_UPLOAD,
            payload: item
        }
    }
);


export const clearForm = createAsyncThunk(
    'Forms/clearForm',
    async (item) => {
        return {
            type: SET_LOADED_FORM,
            payload: item
        }
    }
);


export const setSelectedDeleteRows = createAsyncThunk(
    'Forms/setSelectedRows',
    async (item) => {
        return {
            type: SET_D_ROWS,
            payload: item
        }
    }
);

export const clearSelectedDeleteRows = createAsyncThunk(
    'Forms/clearSelectedRows',
    async (item) => {
        return {
            type: CLEAR_D_ROWS,
            payload: item
        }
    }
);


export const settingUserToReportTo = createAsyncThunk(
    'Forms/settingUserToReportTo',
    async (item) => {
        return {
            type: SET_USER_REPORT,
            payload: item
        }
    }
);

export const addUserResponse = createAsyncThunk(
    'Forms/addUserResponse',
    async (item) => {
        return {
            type: ADD_USER_RESPONSE,
            payload: item
        }
    }
);



export const setAssignedUserEdit = createAsyncThunk(
    'Forms/setAssignedUserEdit',
    async (item) => {
        return {
            type: SET_ASSIGNED_USER_EDIT,
            payload: item
        }
    }
);

export const setAssignedUser = createAsyncThunk(
    'Forms/setAssignedUser',
    async (item) => {
        return {
            type: SET_ASSIGNED_USER,
            payload: item
        }
    }
);

export const setReportingAssignedUser = createAsyncThunk(
    'Forms/setReportingAssignedUser',
    async (item) => {
        return {
            type: SET_REPORTING_ASSIGNED_USER,
            payload: item
        }
    }
);


export const setAssignedReportingJobPositions = createAsyncThunk(
    'Forms/setAssignedReportingJobPositions',
    async (item) => {
        return {
            type: UPDATE_ASSIGNED_REPORTING_JOB_POSITION,
            payload: item
        }
    }
);


export const deleteUserResponse = createAsyncThunk(
    'Forms/deleteUserResponse',
    async (item) => {
        return {
            type: DELETE_USER_RESPONSE,
            payload: item
        }
    }
);


export const setChecklistTrueText = createAsyncThunk(
    'Forms/setChecklistTrueText',
    async (item) => {
        return {
            type: SET_CHECKLIST_TRUE_TEXT,
            payload: item
        }
    }
);


export const setChecklistFalseText = createAsyncThunk(
    'Forms/setChecklistFalseText',
    async (item) => {
        return {
            type: SET_CHECKLIST_FALSE_TEXT,
            payload: item
        }
    }
);


export const setFormName = createAsyncThunk(
    'Forms/setFormName',
    async (item) => {
        return {
            type: SET_FORM_NAME,
            payload: item
        }
    }
);


export const addColumnDuringCreation = createAsyncThunk(
    'Forms/addColumn',
    async (item) => {
        return {
            type: ADD_COLUMN,
            payload: item
        }
    }
);
export const removeColumnDuringCreation = createAsyncThunk(
    'Forms/removeColumn',
    async (item) => {
        return {
            type: REMOVE_COLUMN,
            payload: item
        }
    }
);


export const setItemsToAdd = createAsyncThunk(
    'Forms/setItemsToAdd',
    async (item) => {
        return {
            type: TOGGLE_ITEM_TO_ADD,
            payload: item
        }
    }
);

export const updateUserResponsePrompt = createAsyncThunk(
    'Forms/updateUserResponsePrompt',
    async (item) => {
        return {
            type: UPDATE_PROMPT,
            payload: item
        }
    }
);



export const setAssignedJobPositionsEdit = createAsyncThunk(
    'Forms/setAssignedJobPositionsEdit',
    async (item) => {
        return {
            type: EDIT_ASSIGNED_JOB_POSITION,
            payload: item
        }
    }
);

export const setAssignedJobPositions = createAsyncThunk(
    'Forms/setAssignedJobPositions',
    async (item) => {
        return {
            type: UPDATE_ASSIGNED_JOB_POSITION,
            payload: item
        }
    }
);




export const setTrackedStatus = createAsyncThunk(
    'Forms/setTrackedStatus',
    async (item) => {
        return {
            type: SET_TRACKED_STATUS,
            payload: item
        }
    }
);




export const setAnswerChoices = createAsyncThunk(
    'Forms/setAnswerChoices',
    async (item) => {
        return {
            type: SET_ANSWER_CHOICES,
            payload: item
        }
    }
);

export const editResponseColumn = createAsyncThunk(
    'Forms/editResponseColumn',
    async (item) => {
        return {
            type: SET_RES_COL,
            payload: item
        }
    }
);




export const addAnswerChoiceColumn = createAsyncThunk(
    'Forms/addAnswerChoiceColumn',
    async (item) => {
        return {
            type: ADD_ANSWER_CHOICE,
            payload: item
        }
    }
);

export const addResponseColumn = createAsyncThunk(
    'Forms/addResponseColumn',
    async (item) => {
        return {
            type: ADD_RES_COL,
            payload: item
        }
    }
);



export const removeAnswerChoice = createAsyncThunk(
    'Forms/removeAnswerChoice',
    async (item) => {
        return {
            type: REMOVE_ANSWER_CHOICE,
            payload: item
        }
    }
);
export const removeResponseColumn = createAsyncThunk(
    'Forms/removeResponseColumn',
    async (item) => {
        return {
            type: REMOVE_RES_COL,
            payload: item
        }
    }
);

export const toggleAllPars = createAsyncThunk(
    'Forms/toggleAllPars',
    async (item) => {
        return {
            type: TOGGLE_PAR,
            payload: item
        }
    }
);

export const toggleAllPurchaseTrigger = createAsyncThunk(
    'Forms/toggleAllPurchaseTrigger',
    async (item) => {
        return {
            type: TOGGLE_ALL_PURCHASE_TRIGGERS,
            payload: item
        }
    }
);

export const toggleReportRowForDeletion = createAsyncThunk(
    'Forms/toggleReportRowForDeletion',
    async (item) => {
        return {
            type: TOGGLE_REPORT_DELETION,
            payload: item
        }
    }
);


export const addReportRow = createAsyncThunk(
    'Forms/addReportRow',
    async (item) => {
        return {
            type: ADD_REPORT_ROW,
            payload: item
        }
    }
);

export const setDueDateOfReport = createAsyncThunk(
    'Forms/setDueDateOfReport',
    async (item) => {
        return {
            type: SET_DUE_DATE,
            payload: item
        }
    }
);

export const deleteSelectedRows = createAsyncThunk(
    'Forms/deleteSelectedRows',
    async (item) => {
        return {
            type: DELETE_SELECTED_ROWS,
            payload: item
        }
    }
);
export const setStartDate = createAsyncThunk(
    'Forms/setStartDate',
    async (item) => {
        return {
            type: SET_START_DATE,
            payload: item
        }
    }
);
export const setEndDate = createAsyncThunk(
    'Forms/setEndDate',
    async (item) => {
        return {
            type: SET_END_DATE,
            payload: item
        }
    }
);


export const setSechdule = createAsyncThunk(
    'Forms/setSechdule',
    async (item) => {
        return {
            type: SET_SCHEDULE,
            payload: item
        }
    }
);
export const setRepeatIntervalType = createAsyncThunk(
    'Forms/setRepeatIntervalType',
    async (item) => {
        return {
            type: SET_INTERVAL_TYPE,
            payload: item
        }
    }
);
export const setIntervalValue = createAsyncThunk(
    'Forms/setIntervalValue',
    async (item) => {
        return {
            type: SET_INTERVAL_VALUE,
            payload: item
        }
    }
);


export const setIntervalAmount = createAsyncThunk(
    'Forms/setIntervalAmount',
    async (item) => {
        return {
            type: SET_INTERVAL_AMOUNT,
            payload: item
        }
    }
);



export const setDayValueOfMonthRecurrence = createAsyncThunk(
    'Forms/setDayValueOfMonthRecurrence',
    async (item) => {
        return {
            type: SET_DAY_VALUE_OF_MONTH,
            payload: item
        }
    }
);
export const setMonthDayToRepeat = createAsyncThunk(
    'Forms/setMonthDayToRepeat',
    async (item) => {
        return {
            type: SET_MONTH_VALUE_OF_MONTH,
            payload: item
        }
    }
);
export const setOrdinalValueMonth = createAsyncThunk(
    'Forms/setOrdinalValueMonth',
    async (item) => {
        return {
            type: SET_ORDINAL_MONTH_VALUE,
            payload: item
        }
    }
);


export const setMonthValueForYearRecurrence = createAsyncThunk(
    'Forms/setMonthValueForYearRecurrence',
    async (item) => {
        return {
            type: SET_MONTH_VALUE_FOR_YEAR,
            payload: item
        }
    }
);

export const setDayValueForYearRecurrence = createAsyncThunk(
    'Forms/setDayValueForYearRecurrence',
    async (item) => {
        return {
            type: SET_DAY_FOR_YEAR,
            payload: item
        }
    }
);


export const setOrdinalValueYear = createAsyncThunk(
    'Forms/setOrdinalValueYear',
    async (item) => {
        return {
            type: SET_ORDINAL_VALUE_YEAR,
            payload: item
        }
    }
);


export const setMonthValueForYearRecurrenceBottom = createAsyncThunk(
    'Forms/setMonthValueForYearRecurrenceBottom',
    async (item) => {
        return {
            type: SET_MONTH_VALUE_YEAR_2,
            payload: item
        }
    }
);
export const setMonthOption = createAsyncThunk(
    'Forms/setMonthOption',
    async (item) => {
        return {
            type: SET_MONTH_OPTION,
            payload: item
        }
    }
);
export const setYearOption = createAsyncThunk(
    'Forms/setYearOption',
    async (item) => {
        return {
            type: SET_YEAR_OPTION,
            payload: item
        }
    }
);

//---- 'Dashboard/More general form functions
//let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?org_id=${data.org_id}&location=${data.createLocation}`)

export const getFormSectionsAssignedToActiveUser = createAsyncThunk('Forms/getFormSectionsAssignedToActiveUser', async (data) => {

    console.log("get Form Sections Assigned To Active User File ; ", data)

    try {

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?org_id=${data.org_id}&location=${data.viewingLocation}&user_id=${data.user_id}&job_id=${'getByUserId'}&sections=${true}&day=${data.today}`)

        const realData = await test.json();

        //TODO might require data processing here when multiple chunks for 1 file is returned
        //also currently multiple files under same name WILL be returned but first in list is used only
        console.log("Returned data :", realData)

        data.result = realData

        return data
    }
    catch (err) {
        console.log("Error posting file :", err)
        return err.message
    }

}
);

//Almost same as above but gets the sections based on job position of logged in user
export const getFormSectionsAssignedToActiveJob = createAsyncThunk('Forms/getFormSectionsAssignedToActiveJob', async (data) => {


    try {
        if (data.job_id) {


            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?org_id=${data.org_id}&location=${data.viewingLocation}&user_id=${'getByJob'}&job_id=${data.job_id}&sections=${true}&day=${data.today}`)

            const realData = await test.json();

            //TODO might require data processing here when multiple chunks for 1 file is returned
            //also currently multiple files under same name WILL be returned but first in list is used only
            console.log("Returned getFormSectionsAssignedToActiveJob :", realData)

            data.result = realData

            return data
        }

    }
    catch (err) {
        console.log("Error posting file :", err)
        return err.message
    }

}
);

export const getFormSectionsReportingToActiveUser = createAsyncThunk('Forms/getFormSectionsReportingToActiveUser', async (data) => {

    console.log("get Form Sections reportto userSubmittedTasks ; ", data)

    try {

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/userSubmittedTasks?org_id=${data.org_id}&location=${data.viewingLocation}&user_id=${data.user_id}&job_id=${data.job_id}&sections=${true}&day=${data.today}`)

        const realData = await test.json();

        //TODO might require data processing here when multiple chunks for 1 file is returned
        //also currently multiple files under same name WILL be returned but first in list is used only
        console.log("Returned data :", realData)

        data.result = realData

        return data
    }
    catch (err) {
        console.log("Error posting file :", err)
        return err.message
    }

}
);


export const getTrackedTasksReq = createAsyncThunk(
    'Forms/getTrackedTasksReq',
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?` +
                new URLSearchParams({
                    org_id: data.org_id,
                    location: data.viewingLocation,
                    user_id: data.user_id,
                    reports: true,
                    CreatedBy: true
                })
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();

            // Optional: Process data if needed
            const processedData = {
                ...data,
                result: responseData
            };

            console.log('Tracked tasks fetched successfully:', processedData);
            return processedData;

        } catch (error) {
            console.error('Error fetching tracked tasks:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const changeSectionName = createAsyncThunk(
    'Forms/changeSectionName',
    async (data) => {
        return {
            type: CHANGE_SECTION_NAME,
            payload: data
        }
    }
);
export const setRequestedReport = createAsyncThunk(
    'Forms/setRequestedReport',
    async (data) => {
        return {
            type: SET_REQUESTED_REPORT,
            payload: data
        }
    }
);


export const initializeJobsAndLoadTasks = createAsyncThunk('Forms/initializeJobsAndLoadTasks', async (data) => {


    try {

        console.log("initializeJobsAndLoadTasks data : ", data)

        let tasksByJob = {}
        await Promise.all(
            data.job_id.map(async (element) => {
                const response = await fetch(
                    `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?org_id=${data.org_id}&location=${data.viewingLocation}&user_id=${'getByJob'}&job_id=${element._id}&sections=${true}&day=${data.today}`
                );

                tasksByJob[element.jobTitle] = await response.json();

                console.log("getting tasks for : ", element.jobTitle, tasksByJob)

            })
        );

        //const realData = await test.json();

        //TODO might require data processing here when multiple chunks for 1 file is returned
        //also currently multiple files under same name WILL be returned but first in list is used only
        console.log("Returned data :", tasksByJob)

        data.result = { tasksByJob }

        return data
    }
    catch (err) {
        console.log("Error posting file :", err)
        return err.message
    }

}
);

const initialState = {
    loadingForms: false,
    Forms: [],
    formError: null,
    loadedForm: '',
    formBeingMade: { sections: [], name: '' },
    rowsSelectedForDeletion: [],
    columnsToAdd: [],
    itemsSelectedForRows: [],
    answerChoices: [{ text: "True", response: '' }, { text: "False", response: '' }],
    responseColumns: [{ text: '', response: '' }],
    sectionsAssignedToUser: [],
    sectionsAssignedToJob: [],
    sectionsReportingToUser: [],
    trackSection: false,
    trackedTasks: [],


    requestedReports: [{
        display: [],
        due: {},
        frequency: {},
        startDate: ''
    }],

    reportsPendingRemoval: [],
    activeCalendars: [],
};


var reportTemplate = {
    display: {},
    due: {},
    frequency: {},
    startDate: '',
    googleEmail: '',
    googlePassword: '',
}

//fields of each section
var dataEntryField = {
    item: '',
    includePar: false,
    triggerPurchase: false,
    userResponse: [{ question: 'Quantity:', answer: '' }],
    parValue: '',
    responseOption: '',
    adminIncludeInOrder: '',
    adminReviewCheckboxValue: '',
    adminReviewCheckboxToggle: 'off'

}

export { dataEntryField };

const checklistField = {
    description: '',
    response: '',
    userResponse: '',
    answerChoices: [{ text: 'True', response: '' }, { text: 'False', response: '' }]
}

const visualField = {
    imageUpload: '',
    description: '',
    userImageUpload: '',
    adminImageUpload: 'none'
}

const responseField = {
    userResponse: '',
    description: '',
    responseColumns: [{ text: '', response: '' }]

}

//Four form types
const dataEntrySection = {
    fields: [dataEntryField],
    type: 'Data Entry',
    destinationFormat: '',
    assignedUser: [],
    assignedJobPositions: [],
    destinationType: '',
    reportToUser: [],
    reportToJobPosition: [],
    frequency: [],
    sectionName: '',

    startDate: '',
    due: '',
    intervalValue: '',
    intervalType: '',
    display: [],

    assignedReportingUser: [],
    assignedReportingJobPosition: []

}

const checklistSection = {
    fields: [checklistField],
    type: 'Checklist',
    destinationFormat: '',
    assignedUser: [],
    assignedJobPositions: [],
    destinationType: '',
    reportToUser: [],
    reportToJobPosition: [],
    frequency: [],
    sectionName: '',
    startDate: '',

    assignedReportingUser: [],
    assignedReportingJobPosition: []
}

const visualSection = {
    fields: [visualField],
    type: "Visual",
    destinationFormat: '',
    assignedUser: [],
    assignedJobPositions: [],
    destinationType: '',
    reportToUser: [],
    reportToJobPosition: [],
    frequency: [],
    sectionName: '',
    startDate: '',

    assignedReportingUser: [],
    assignedReportingJobPosition: []
}

const responseSection = {
    fields: [responseField],
    type: 'Response',
    destinationFormat: '',
    assignedUser: [],
    assignedJobPositions: [],
    destinationType: '',
    reportToUser: [],
    reportToJobPosition: [],
    frequency: [],
    sectionName: '',
    startDate: '',

    assignedReportingUser: [],
    assignedReportingJobPosition: []
}



// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks
export const fetchForms = createAsyncThunk(
    'Forms/fetchForms',
    async (data) => {
        try {
            console.log("Fetching forms orgid: ", data, data.orgId)

            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms?org_id=${data.org_id}&location=${data.createLocation}`)

            const realData = await test.json();

            return realData

        }
        catch (err) {
            return err.message
        }

    }
);

export const addNewForm = createAsyncThunk('Forms/addNewForm', async (initialData) => {

    try {

        console.log("Check create ; ", initialData)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(initialData),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        return err.message
    }

}
);

export const getOneFile = createAsyncThunk('Forms/getOneFile', async (data) => {

    console.log("getOneFile File ; ", data)

    try {

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/files?` + new URLSearchParams({ name: data.value }))


        const realData = await test.json();

        //TODO might require data processing here when multiple chunks for 1 file is returned
        //also currently multiple files under same name WILL be returned but first in list is used only
        console.log("Returned data :", realData)
        data.result = realData
        return data

        //const realData = await test.json();

        //return realData
    }
    catch (err) {
        console.log("Error getting file :", err)
        return err.message
    }

}
);




export const patchFormSection = createAsyncThunk('Forms/patchSubmissionOfSection', async (data) => {

    try {

        console.log("patch ; ", data, data.checkout)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms/${data._id}?submission=${true}&patchSection=${true}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

});




export const patchSubmissionOfSection = createAsyncThunk('Forms/patchSubmissionOfSection', async (data) => {

    try {

        console.log("patch ; ", data, data.checkout)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms/${data._id}?submission=${true}&cart=${encodeURIComponent(JSON.stringify(data?.checkout || {}))}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

});

export const patchForm = createAsyncThunk('Forms/patchForm', async (data) => {
    try {
        console.log("patch ; ", data, data._id);

        let requestBody;
        if (data.sectionData) {
            // If sectionData is provided, use it as the request body
            requestBody = JSON.stringify({ section: data.sectionData });
        } else {
            // Otherwise, use the original data object
            requestBody = JSON.stringify(data);
        }

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms/${data._id ?? data.formId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: requestBody,
        });

        const realData = await test.json();
        return realData;
    } catch (err) {
        console.log("whats my error : ", err.message);
        return err.message;
    }
});

export const deleteForm = createAsyncThunk('Forms/deleteForm', async (toDelete) => {

    try {

        console.log("deleting ; ", toDelete)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/forms/${toDelete._id}`, {
            method: 'delete',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(toDelete),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

}
);



export const setActiveCalendarList
    = createAsyncThunk(
        'Calendar/setActiveCalendarList',
        async (calendar) => {
            return {
                type: SET_ACTIVE_CALENDAR_LIST,
                payload: calendar
            }
        }
    );




export const Formslice = createSlice({
    name: 'forms',
    initialState,

    
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {





        FETCH_Forms_REQUESTED: (state, action) => {
            return state
        },

        FETCH_Forms_SUCCEDED: (state, action) => {
            return { loading: false, Forms: action.payload, error: '' }
        },

        FETCH_Forms_FAILED: (state, action) => {
            // console.log("fetch Forms failed reached")
            return { loading: false, Forms: [], error: action.payload }
        },

    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            //Fetching Forms
            .addCase(fetchForms.pending, (state) => {
                state.loadingForms = true;
                state.FormsError = ''

            })
            .addCase(fetchForms.fulfilled, (state, action) => {
                state.loadingForms = 'Done';

                console.log("Action in fetch : ", action)

                state.Forms = action.payload
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })


            .addCase(setDueDateOfReport.fulfilled, (state, action) => {
                console.log('setDueDateOfReport: ', action, action.payload.payload.value)


                state.formBeingMade.sections[action.payload.payload.sectionIndex].due = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setDisplayOfReport.fulfilled, (state, action) => {
                console.log('setDisplayOfReport: ', action, action.value)


                state.formBeingMade.sections[action.payload.payload.sectionIndex].display = action.payload.payload.value

                state.FormsError = ''
            })



            .addCase(setActiveCalendarList.fulfilled, (state, action) => {
                console.log("Setting activeCalendars : ", action)

                state.activeCalendars = action.payload.payload
            })
            .addCase(setAnswerChoices.fulfilled, (state, action) => {
                const { data, sectionIndex, fieldIndex, columnIndex } = action.payload.payload;
                
                console.log("recieved payload :" ,  action.payload)
               // const section = state.formBeingMade.sections[sectionIndex];
                    state.formBeingMade.sections[sectionIndex].fields[fieldIndex].answerChoices[columnIndex].text = data;
            
                state.FormsError = '';
            })


            .addCase(setGoogleEmail.fulfilled, (state, action) => {
                console.log("Setting setGoogleEmail : ", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].googleEmail = action.payload.payload.value

            })


            .addCase(setGooglePassword.fulfilled, (state, action) => {
                console.log("Setting setGooglePassword : ", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].googlePassword = action.payload.payload.value
            })

            .addCase(changeSectionName.fulfilled, (state, action) => {
                console.log('Change section name action: ', action);

                const { sectionIndex, sectionName } = action.payload.payload;

                console.log("check data ; ", action.payload.payload.name, action.payload.payload.index)
                state.formBeingMade.sections[action.payload.payload.index].sectionName = action.payload.payload.name;

                state.FormsError = '';
            })

            .addCase(setEndDate.fulfilled, (state, action) => {
                console.log('setStartDate: ', action, action.value)


                state.formBeingMade.sections[action.payload.payload.sectionIndex].endDate = action.payload.payload.data

                state.FormsError = ''
            })

            .addCase(updateFormSections, (state, action) => {
                console.log("Updating form sections:", action.payload);
                state.formBeingMade.sections = action.payload;
            })

            .addCase(setRequestedReport.fulfilled, (state, action) => {
                console.log('setRequestedReport: ', action);

                const { payload } = action.payload;

                state.requestedReports[0] = {
                    ...state.requestedReports[0],
                    ...payload,
                };

                state.FormsError = '';
            })


            .addCase(setOrdinalValueYear.fulfilled, (state, action) => {
                console.log('setOrdinalValueYear: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].ordinalValueForYear = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setDayValueForYearRecurrence.fulfilled, (state, action) => {
                console.log('setDayValueForYearRecurrence: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].dayValueForYear = action.payload.payload.value

                state.FormsError = ''
            })

            .addCase(setMonthValueForYearRecurrenceBottom.fulfilled, (state, action) => {
                console.log('setMonthValueForYearRecurrenceBottom: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].monthValue2ForYear = action.payload.payload.value

                state.FormsError = ''
            })
            .addCase(reorderFields, (state, action) => {
                const { sectionIndex, sourceIndex, destinationIndex } = action.payload;
                const section = state.formBeingMade.sections[sectionIndex];
                const fields = Array.from(section.fields);
                const [reorderedItem] = fields.splice(sourceIndex, 1);
                fields.splice(destinationIndex, 0, reorderedItem);
                section.fields = fields;
            })
            .addCase(setMonthValueForYearRecurrence.fulfilled, (state, action) => {
                console.log('setMonthValueForYearRecurrence: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].monthValueForYear = action.payload.payload.value

                state.FormsError = ''
            })

            .addCase(setDayValueOfMonthRecurrence.fulfilled, (state, action) => {
                console.log('setDayValueOfMonthRecurrence: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].dayValueForMonth = action.payload.payload.value

                state.FormsError = ''
            })
            .addCase(setMonthDayToRepeat.fulfilled, (state, action) => {
                console.log('setMonthDayToRepeat: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].monthDayToRepeat = action.payload.payload.value

                state.FormsError = ''
            })
            .addCase(setOrdinalValueMonth.fulfilled, (state, action) => {
                console.log('setOrdinalValueMonth: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].ordinalMonthValue = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setMonthOption.fulfilled, (state, action) => {
                console.log('setMonthOption: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].monthOption = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setYearOption.fulfilled, (state, action) => {
                console.log('setYearOption: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].yearOption = action.payload.payload.value

                state.FormsError = ''
            })



            .addCase(setIntervalAmount.fulfilled, (state, action) => {
                console.log('setIntervalAmount: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].intervalAmount = action.payload.payload.value

                state.FormsError = ''
            })

            .addCase(setIntervalValue.fulfilled, (state, action) => {
                console.log('setIntervalValue: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].intervalValue = action.payload.payload.value

                state.FormsError = ''
            })
            .addCase(setRepeatIntervalType.fulfilled, (state, action) => {
                console.log('setRepeatIntervalType: ', action, action.value)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].intervalType = action.payload.payload.value.label

                state.FormsError = ''
            })

            .addCase(setStartDate.fulfilled, (state, action) => {
                console.log('setStartDate: ', action, action.value)


                state.formBeingMade.sections[action.payload.payload.sectionIndex].startDate = action.payload.payload.data

                state.FormsError = ''
            })

            .addCase(addReportRow.fulfilled, (state, action) => {
                console.log('Pushing another report template')
                state.requestedReports.push(reportTemplate)
                //   state.Forms = action.payload
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })



            .addCase(setSechdule.fulfilled, (state, action) => {
                console.log('Pushing another report template')


                let reports = action.payload.payload.requestedReports

                console.log("Reports :", reports)


                state.formBeingMade.sections[action.payload.payload.sectionIndex].schedule = reports

                state.FormsError = ''
            })

            .addCase(toggleReportRowForDeletion.fulfilled, (state, action) => {
                console.log('toggleReportRowForDeletion', action, action.payload.payload)

                if (action?.payload?.payload?.evt == true) {
                    state.reportsPendingRemoval.push(action.payload.payload.rowIndex)
                }
                else {
                    let temp = structuredClone(action.payload.payload.newReports)

                    console.log("temp check 2 :", temp)

                    for (let i = 0; i < temp.length; i++) {
                        const element = temp[i];
                        console.log("Check removal thing : ", element, action.payload.payload.rowIndex)
                        if (element == action.payload.payload.rowIndex) {
                            temp.splice(i, 1)
                        }
                    }
                    console.log("Trimmed temp : ", temp)
                    state.reportsPendingRemoval = temp

                }
                state.FormsError = ''
            })



            .addCase(deleteSelectedRows.fulfilled, (state, action) => {
                console.log('deleteSelectedRows', action,)

                let temp = structuredClone(action.payload.payload.reports)
                let temp2 = structuredClone(action.payload.payload.toRemove)
                temp2.sort().reverse().forEach(element => {

                    temp.splice(element, 1)
                });

                console.log('deleteSelectedRows2', temp)
                state.requestedReports = temp
                state.reportsPendingRemoval = []

                state.FormsError = ''
            })

            .addCase(getTrackedTasksReq.fulfilled, (state, action) => {
                console.log("Action in fetch getTrackedTasksReq : ", action)

                state.trackedTasks = action.payload.result.docs


                //  state.sectionsReportingToUser = action.payload.result.docs

                let temp = state.trackedTasks
                let i = 0
                console.log("Temp2 : ", temp, action.payload.result.docs)
                /*
                action.payload.result.docs.forEach(element => {
                    console.log("Removing2 : ", element)
                    temp.splice(i, 1)
                    i++
                });*/

                state.trackedTasks = temp
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })



            .addCase(initializeJobsAndLoadTasks.fulfilled, (state, action) => {
                console.log("Action in fetch initializeJobsAndLoadTasks : ", action)

                state.sectionsAssignedToJob = action?.payload?.result?.tasksByJob ?? []
                state.FormsError = ''
            })





            .addCase(getFormSectionsAssignedToActiveJob.fulfilled, (state, action) => {
                console.log("Action in fetch getFormSectionsAssignedToActiveJob : ", action)

                state.sectionsAssignedToJob = action.payload?.result?.docs
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })





            .addCase(getFormSectionsAssignedToActiveUser.fulfilled, (state, action) => {
                console.log("Action in fetch getFormSectionsAssignedToActiveUser : ", action)

                state.sectionsAssignedToUser = action.payload?.result?.docs
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })

            .addCase(getFormSectionsReportingToActiveUser.fulfilled, (state, action) => {
                console.log("Action in fetch sectionsReportingToUser : ", action)

                state.sectionsReportingToUser = action?.payload?.result?.docs

                /*
                let temp = state.trackedTasks
                let i = 0
                console.log("Temp : ", temp, action.payload.result.docs)
                action.payload.result.docs.forEach(element => {
                    console.log("Removing : ", element)
                    temp.splice(i, 1)
                    i++
                });

                state.trackedTasks = temp*/
                state.FormsError = ''
                // console.log("data :", state.Forms, action)
            })
            .addCase(fetchForms.rejected, (state, action) => {
                state.loadingForms = false;
                state.forms = []
                console.log("Error message ;", action.error)
                state.FormsError = action.error.message
            })

            .addCase(clearForm.fulfilled, (state, action) => {
                state.formBeingMade = initialState.formBeingMade
                state.FormsError = ''
            })


            .addCase(setFrequency.fulfilled, (state, action) => {

                console.log("Setting frequnecy : ", action, action.payload.payload.value)
                state.formBeingMade.sections[action.payload.payload.sectionIndex - 1].frequency = action.payload.payload.value

                state.FormsError = ''

                //   state.tasksError = ''
            })

            .addCase(clearSelectedDeleteRows.fulfilled, (state, action) => {
                state.rowsSelectedForDeletion = []
            })



            .addCase(setSelectedDeleteRows.fulfilled, (state, action) => {
                state.rowsSelectedForDeletion = action.payload.payload;
            })



            .addCase(settingJobToReportTo.fulfilled, (state, action) => {
                console.log("reportToJobPosition", action)
                state.formBeingMade.sections[action.payload.payload.sectionIndex - 1].reportToJobPosition = action.payload.payload.e
            })

            .addCase(settingUserToReportTo.fulfilled, (state, action) => {
                console.log("reportToUser", action)
                state.formBeingMade.sections[action.payload.payload.sectionIndex - 1].reportToUser = action.payload.payload.e
            })




            .addCase(setTrackedStatus.fulfilled, (state, action) => {

                console.log(" Track section : ", action)
                state.formBeingMade.sections[action.payload.payload.sectionIndex].trackSection = action.payload.payload.status
                state.tasksError = ''
            })


            .addCase(setDestinationType.fulfilled, (state, action) => {

                state.formBeingMade.sections[action.payload.payload.sectionIndex].destinationType = action.payload.payload.value
                state.tasksError = ''
            })

            /* export const setReportingAssignedUser = createAsyncThunk(
                 'Forms/setReportingAssignedUser',
                 async (item) => {
                     return {
                         type: SET_REPORTING_ASSIGNED_USER,
                         payload: item
                     }
                 }
             );
             
             
             export const setAssignedReportingJobPositions = createAsyncThunk(
                 'Forms/setAssignedReportingJobPositions',
                 async (item) => {
                     return {
                         type: UPDATE_ASSIGNED_REPORTING_JOB_POSITION,
                         payload: item
                     }
                 }
             );*/

            .addCase(setReportingAssignedUser.fulfilled, (state, action) => {
                const { fromCustomSelect, sectionIndex, clickedOption, e } = action.payload.payload;

                if (e?.length == 0 || e == []) {//for the reset
                    state.formBeingMade.sections[sectionIndex - 1].assignedReportingUser = e
                }
                else {

                    if (fromCustomSelect) {
                        if (state.formBeingMade.sections[sectionIndex - 1]) {
                            let currentUsers = state.formBeingMade.sections[sectionIndex - 1].assignedReportingUser || [];

                            if (clickedOption) {
                                const userIndex = currentUsers.findIndex(user => user._id === clickedOption._id);
                                if (userIndex === -1) {
                                    const isDuplicate = currentUsers.some(user => user._id === clickedOption._id);
                                    if (!isDuplicate) {
                                        currentUsers = [...currentUsers, clickedOption];
                                    }
                                } else {
                                    currentUsers = currentUsers.filter(user => user._id !== clickedOption._id);
                                }
                                state.formBeingMade.sections[sectionIndex - 1].assignedReportingUser = currentUsers;
                            }
                        }
                    } else {
                        const uniqueUsers = Array.isArray(e)
                            ? [...new Map(e.map(item => [item._id, item])).values()]
                            : e;
                        state.formBeingMade.sections[sectionIndex - 1].assignedReportingUser = uniqueUsers;
                    }
                }

                state.tasksError = '';
            })

            .addCase(setAssignedReportingJobPositions.fulfilled, (state, action) => {
                const { fromCustomSelect, sectionIndex, clickedOption, e } = action.payload.payload;


                if (e?.length == 0 || e == []) {//for the reset
                    state.formBeingMade.sections[sectionIndex - 1].assignedReportingJobPosition = e
                }
                else {
                    if (fromCustomSelect) {
                        if (state.formBeingMade.sections[sectionIndex - 1]) {
                            let currentPositions = state.formBeingMade.sections[sectionIndex - 1].assignedReportingJobPosition || [];

                            if (clickedOption) {
                                const positionIndex = currentPositions.findIndex(position => position._id === clickedOption._id);
                                if (positionIndex === -1) {
                                    const isDuplicate = currentPositions.some(pos => pos._id === clickedOption._id);
                                    if (!isDuplicate) {
                                        currentPositions = [...currentPositions, clickedOption];
                                    }
                                } else {
                                    currentPositions = currentPositions.filter(position => position._id !== clickedOption._id);
                                }
                                state.formBeingMade.sections[sectionIndex - 1].assignedReportingJobPosition = currentPositions;
                            }
                        }
                    } else {
                        const uniquePositions = Array.isArray(e)
                            ? [...new Map(e.map(item => [item._id, item])).values()]
                            : [e];  // Wrap single item in array as per original logic
                        state.formBeingMade.sections[sectionIndex - 1].assignedReportingJobPosition = uniquePositions;
                    }

                }

                state.tasksError = '';
            })

            .addCase(setAssignedUser.fulfilled, (state, action) => {



                console.log("Recieved set assigned user :", action.payload.payload)

                const { fromCustomSelect, sectionIndex, clickedOption, e } = action.payload.payload;
                if (e?.length == 0 || e == []) {//for the reset
                    state.formBeingMade.sections[sectionIndex - 1].assignedUser = e
                }
                else {
                    if (fromCustomSelect) {
                        if (state.formBeingMade.sections[sectionIndex - 1]) {
                            let currentUsers = state.formBeingMade.sections[sectionIndex - 1].assignedUser || [];

                            if (clickedOption) {
                                // Check if item already exists
                                const userIndex = currentUsers.findIndex(user => user._id === clickedOption._id);
                                if (userIndex === -1) {
                                    // Only add if not already present
                                    const isDuplicate = currentUsers.some(user => user._id === clickedOption._id);
                                    if (!isDuplicate) {
                                        currentUsers = [...currentUsers, clickedOption];
                                    }
                                } else {
                                    currentUsers = currentUsers.filter(user => user._id !== clickedOption._id);
                                }
                                state.formBeingMade.sections[sectionIndex - 1].assignedUser = currentUsers;
                            }
                        }
                    } else {
                        // If not from CustomSelect, ensure the array has unique items
                        const uniqueUsers = Array.isArray(e)
                            ? [...new Map(e.map(item => [item._id, item])).values()]
                            : e;
                        state.formBeingMade.sections[sectionIndex - 1].assignedUser = uniqueUsers;
                    }
                }



                state.tasksError = '';
            })

            .addCase(setAssignedUserEdit.fulfilled, (state, action) => {
                console.log('Action recieved. set user ', action, state.formBeingMade.sections[action.payload.payload.sectionIndex])
                state.formBeingMade.sections[action.payload.payload.sectionIndex - 1].assignedUser = action.payload.payload.e
                state.tasksError = ''

            })

            .addCase(setAssignedJobPositions.fulfilled, (state, action) => {
                const { fromCustomSelect, sectionIndex, clickedOption, e } = action.payload.payload;


                if (e?.length == 0 || e == []) {//for the reset
                    state.formBeingMade.sections[sectionIndex - 1].assignedJobPositions = e
                }
                else {

                    if (fromCustomSelect) {
                        if (state.formBeingMade.sections[sectionIndex - 1]) {
                            let currentPositions = state.formBeingMade.sections[sectionIndex - 1].assignedJobPositions || [];

                            if (clickedOption) {
                                // Check if item already exists
                                const positionIndex = currentPositions.findIndex(position => position._id === clickedOption._id);
                                if (positionIndex === -1) {
                                    // Only add if not already present
                                    const isDuplicate = currentPositions.some(pos => pos._id === clickedOption._id);
                                    if (!isDuplicate) {
                                        currentPositions = [...currentPositions, clickedOption];
                                    }
                                } else {
                                    currentPositions = currentPositions.filter(position => position._id !== clickedOption._id);
                                }
                                state.formBeingMade.sections[sectionIndex - 1].assignedJobPositions = currentPositions;
                            }
                        }
                    } else {
                        // If not from CustomSelect, ensure the array has unique items
                        const uniquePositions = Array.isArray(e)
                            ? [...new Map(e.map(item => [item._id, item])).values()]
                            : e;
                        state.formBeingMade.sections[sectionIndex - 1].assignedJobPositions = uniquePositions;
                    }
                }

                state.tasksError = '';
            })

            .addCase(setAssignedJobPositionsEdit.fulfilled, (state, action) => {
                console.log('Action recieved. set job position ', action, state.formBeingMade.sections[action.payload.payload.sectionIndex])
                state.formBeingMade.sections[action.payload.payload.sectionIndex - 1].assignedJobPositions = action.payload.payload.e
                state.tasksError = ''
            })





            .addCase(setDestinationFormat.fulfilled, (state, action) => {
                console.log('Set destination format action : ', action)
                state.formBeingMade.sections[action.payload.payload.sectionIndex].destinationFormat = action.payload.payload.evt
                state.tasksError = ''
            })

            .addCase(setFormForEdit.rejected, (state, action) => {

                console.log("set form being made REJECTED:", state.formBeingMade, action.payload)

                state.FormsError = ''
            })

            .addCase(setFormForEdit.fulfilled, (state, action) => {
                console.log("set form being made:", action.payload.payload);

                // Deep copy the entire form structure
                state.formBeingMade = {
                    ...state.formBeingMade,
                    name: action.payload.payload.name,
                    sections: action.payload.payload.sections.map(section => ({
                        ...section,
                        assignedReportingUser: section.assignedReportingUser || [],
                        assignedReportingJobPosition: section.assignedReportingJobPosition || [],
                        assignedUser: section.assignedUser || [],
                        assignedJobPositions: section.assignedJobPositions || [],
                        fields: section.fields || []
                    }))
                };

                console.log("Updated formBeingMade:", state.formBeingMade);
                state.FormsError = '';
            })

            //Creating Tasks
            .addCase(getOneFile.fulfilled, (state, action) => {
                console.log("getOneFile Success :", state, action, action.payload.sectionIndex, state.formBeingMade.sections[action.payload.sectionIndex])

                state.formBeingMade.sections[action.payload.sectionIndex].fields[action.payload.fieldIndex].adminImageUpload = action.payload.result.chunks[0].data

                //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
                state.tasksError = ''
            })


            .addCase(addFieldToSection.fulfilled, (state, action) => {

                console.log("addFieldToSection :", action)


                if (action.payload.payload.type === 'Data Entry') {
                    const sectionIndex = action.payload.payload.index;
                    const fields = state.formBeingMade.sections[sectionIndex].fields;

                    // Get the last field as template
                    const lastField = fields[fields.length - 1];

                    // Create deep copy of the last field
                    const newField = {
                        ...lastField,
                        description: lastField.description || '',
                        response: lastField.response || '',
                        userResponse: lastField.userResponse || '',
                        answerChoices: lastField.answerChoices ? [...lastField.answerChoices] : [],
                        // Add any other specific field properties you want to copy or reset
                    };

                    // Push the new field to the section
                    state.formBeingMade.sections[sectionIndex].fields.push(newField);

                    console.log("Data entry field added successfully", {
                        sectionIndex,
                        newFieldCount: state.formBeingMade.sections[sectionIndex].fields.length
                    })
                }
                if (action.payload.payload.type == 'Checklist') {
                    let checklistField2 = {
                        description: '',
                        response: '',
                        userResponse: '',
                        answerChoices: []
                    }
                    state.formBeingMade.sections[action.payload.payload.index].fields.push(checklistField2)
                }
                if (action.payload.payload.type == 'Visual') {
                    state.formBeingMade.sections[action.payload.payload.index].fields.push(visualField)
                }

                if (action.payload.payload.type == 'Response') {
                    state.formBeingMade.sections[action.payload.payload.index].fields.push(responseField)
                }

                state.FormsError = ''
            })




            .addCase(removeFieldFromSection.fulfilled, (state, action) => {

                console.log("removeFieldFromSection check:", action)
                state.formBeingMade.sections[action.payload.payload.index.section].fields.splice(action.payload.payload.index.field - action.payload.payload.iAdjust, 1)

                if (state.formBeingMade.sections[action.payload.payload.index.section].fields.length == 0) {
                    console.log("Slicing")
                    state.formBeingMade.sections.splice(action.payload.payload.index.section, 1)
                }
                state.FormsError = ''
            })


            //Creating Forms
            .addCase(AddSection.fulfilled, (state, action) => {

                console.log("adds ection : ", action)
                //based on type add different field object thing here (need one initial element)
                if (action.payload.payload == 'Data Entry Form') {
                    state.formBeingMade.sections.push(JSON.parse(JSON.stringify(dataEntrySection)))
                }
                if (action.payload.payload == 'Checklist Form') {
                    state.formBeingMade.sections.push(JSON.parse(JSON.stringify(checklistSection)))
                }
                if (action.payload.payload == 'Visual Form') {
                    state.formBeingMade.sections.push(JSON.parse(JSON.stringify(visualSection)))
                }

                if (action.payload.payload == 'Response Form') {
                    state.formBeingMade.sections.push(JSON.parse(JSON.stringify(responseSection)))
                }

                state.FormsError = ''
            })

            .addCase(AddSection.rejected, (state, action) => {
                console.log("rejected :", action)

                state.FormsError = ''
            })






            .addCase(toggleAllPurchaseTrigger.fulfilled, (state, action) => {

                console.log("toggling all purchase triggers :", action)
                if (action.payload.payload.checked == true) {
                    state.formBeingMade.sections[action.payload.payload.sectionIndex].fields.forEach(field => {
                        field.triggerPurchase = true
                    })
                }
                else {
                    if (action.payload.payload.checked == false) {
                        state.formBeingMade.sections[action.payload.payload.sectionIndex].fields.forEach(field => {
                            field.triggerPurchase = false
                        });
                    }

                    state.FormsError = ''
                }

            })




            .addCase(toggleAllPars.fulfilled, (state, action) => {
                console.log("Toggle all par actions : ", action)
                if (action.payload.payload.checked == true) {
                    state.formBeingMade.sections[action.payload.payload.sectionIndex].fields.forEach(field => {
                        field.includePar = true
                    })
                }
                else {
                    if (action.payload.payload.checked == false) {
                        state.formBeingMade.sections[action.payload.payload.sectionIndex].fields.forEach(field => {
                            field.includePar = false
                        });
                    }

                    state.FormsError = ''
                }

            })



            .addCase(setTriggerPurchase.fulfilled, (state, action) => {

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].triggerPurchase = action.payload.payload.status


                state.FormsError = ''
            })


            .addCase(setIncludePar.fulfilled, (state, action) => {

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].includePar = action.payload.payload.status


                state.FormsError = ''
            })

            .addCase(setAction.fulfilled, (state, action) => {

                console.log("Setting action : ", action.payload.payload)
                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].action = action.payload.payload.action


                state.FormsError = ''
            })


            .addCase(setFormName.fulfilled, (state, action) => {
                console.log("Checking name :", action)
                state.formBeingMade.name = action.payload.payload
            })



            //Removing Sections
            .addCase(RemoveSection.fulfilled, (state, action) => {


                console.log("Removing section :", action)
                state.formBeingMade.sections.splice(-1, 1); // 2nd parameter means remove one item only

                //TODO splice here deletes last element every time because no key attribute has been set

                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })


            //Assigning input when changed
            .addCase(setParValue.fulfilled, (state, action) => {

                console.log("setParValue Action coming :", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].parValue = action.payload.payload.par


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })


            //Assigning input when changed
            .addCase(setParValue.rejected, (state, action) => {

                console.log("rejected par value :", action)

                // state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].parValue = action.payload.payload.par


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(setVisualFileUpload.fulfilled, (state, action) => {

                console.log("TODO: Going to implement at a later time, i dont know easiest way to save/upload files to firebase storage, also idk if we have cloud storage setup")
                console.log("Incoming action :", action)
                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].adminImageUpload = action.payload.payload.files[0]


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })


            //Assigning input when changed
            .addCase(setResponseDescription.fulfilled, (state, action) => {

                console.log("setResponseDescription Action coming :",
                    state.formBeingMade.sections.length - 1,
                    action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].responseColumns[action.payload.payload.columnIndex].text = action.payload.payload.text


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(setVisualDescription.fulfilled, (state, action) => {

                console.log("setVisualDescription Action coming :", action.payload.payload)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].description = action.payload.payload.text


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(setChecklistAdminResponse.fulfilled, (state, action) => {

                console.log("setChecklistAdminResponse Action coming :", action.payload.payload)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].response = action.payload.payload.status


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })



            .addCase(setChecklistTrueText.pending, (state, action) => {
                console.log("setChecklistTrueText Action pending? :", action)
            })


            .addCase(setChecklistTrueText.rejected, (state, action) => {
                console.log("setChecklistTrueText Action rejected? :", action)
            })



            .addCase(setItemsToAdd.fulfilled, (state, action) => {
                console.log("setItemsToAdd Action :", action)

                state.formBeingMade.itemsSelectedForRows = action.payload.payload.tempList
            })

            .addCase(SaveItemToField.fulfilled, (state, action) => {
                const { item, sectionIndex, fieldIndex } = action.payload.payload;

                // Check if the section and field exist
                if (state.formBeingMade.sections[sectionIndex]?.fields[fieldIndex]) {
                    if (!item || item === null) {
                        // Handle reset/clear case
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].item = null;
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].parValue = '';
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].includePar = false;
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].triggerPurchase = false;
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].userResponse =
                            state.formBeingMade.sections[sectionIndex].fields[fieldIndex].userResponse.map(resp => ({
                                ...resp,
                                answer: ''
                            }));
                    } else {
                        // Set the new item with all its properties
                        state.formBeingMade.sections[sectionIndex].fields[fieldIndex].item = {
                            _id: item._id,
                            itemName: item.itemName,
                            cost: item.cost || '',
                            itemAmount: item.itemAmount || '',
                            par: item.par || '',
                            size: item.size || '',
                            itemNumber: item.itemNumber || '',
                            orderTriggerValue: item.orderTriggerValue || '',
                            description: item.description || '',
                            pendingOrderQuantity: item.pendingOrderQuantity || '',
                            dateOfCost: item.dateOfCost || '',
                            vendor: item.vendor || { label: '', value: '' },
                            orderTriggerIneq: item.orderTriggerIneq || '-',
                            reportingGroup: item.reportingGroup || [],
                            viewingLocation: item.viewingLocation,
                            org_id: item.org_id
                        };
                    }
                }

                state.tasksError = '';
            })

            .addCase(setChecklistTrueText.fulfilled, (state, action) => {

                console.log("setChecklistTrueText Action coming :", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].trueText = action.payload.payload.text

                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(setChecklistFalseText.fulfilled, (state, action) => {

                console.log("setUserResponsePrompt Action coming :", action.payload.payload)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].falseText = action.payload.payload.text

                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(setChecklistDescription.fulfilled, (state, action) => {
                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].description = action.payload.payload.text
                state.FormsError = ''
            })

            .addCase(updateUserResponsePrompt.fulfilled, (state, action) => {
                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse[action.payload.payload.columnIndex].question = action.payload.payload.prompt
            })


            //Assigning input when changed
            .addCase(editResponseColumn.fulfilled, (state, action) => {

                console.log("editResponseColumn reached : ", action)
                state.responseColumns[action.payload.payload.columnIndex].text = action.payload.payload.data

                for (let i = 0; i < state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields.length; i++) {
                    const element = state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i];
                    state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i].responseColumns[action.payload.payload.columnIndex].text = action.payload.payload.data
                }

                state.FormsError = ''
            })

            /*
                        .addCase(setAnswerChoices.fulfilled, (state, action) => {
                            // Get the data from the correct payload level
                            const data = action.payload.payload;
                        
                            if (data.type === 'all') {
                                // Update state.answerChoices if it exists
                                if (state.answerChoices?.[data.columnIndex]) {
                                    state.answerChoices[data.columnIndex].text = data.data;
                                }
                        
                                // Update all fields in the last section
                                const lastSectionIndex = state.formBeingMade.sections.length - 1;
                                const lastSection = state.formBeingMade.sections[lastSectionIndex];
                                
                                if (lastSection?.fields) {
                                    lastSection.fields.forEach(field => {
                                        if (field?.answerChoices?.[data.columnIndex]) {
                                            field.answerChoices[data.columnIndex].text = data.data;
                                        }
                                    });
                                }
                            } else {
                                // Update single field
                                if (typeof data.sectionIndex === 'number' && 
                                    typeof data.fieldIndex === 'number' && 
                                    typeof data.columnIndex === 'number') {
                                    
                                    const section = state.formBeingMade.sections[data.sectionIndex];
                                    if (section?.fields?.[data.fieldIndex]?.answerChoices?.[data.columnIndex]) {
                                        section.fields[data.fieldIndex].answerChoices[data.columnIndex].text = data.data;
                                    }
                                }
                            }
                        
                            state.FormsError = '';
                        })
            */
            //Assigning input when changed
            .addCase(setUserResponsePrompt.fulfilled, (state, action) => {

                console.log("setUserResponsePrompt question coming :", action.payload.payload)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse[action.payload.payload.columnIndex].question = action.payload.payload.prompt

                //  state.formBeingMade.sections[action.payload.payload.sectionIndex].fields
                if (action.payload.payload.type == 'all') {

                    for (let i = 0; i < state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields.length; i++) {
                        const element = state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i];
                        state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i].userResponse[action.payload.payload.columnIndex].question = action.payload.payload.prompt
                    }

                }
                else {
                    state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse[action.payload.payload.columnIndex].question = action.payload.payload.prompt

                }


                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })

            //Assigning input when changed
            .addCase(addUserResponse.fulfilled, (state, action) => {

                console.log("setUserResponsePrompt Action coming :", action.payload.payload)

                console.log("Sanity check : ", state.formBeingMade.sections[0])

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse.push({ question: '', answer: '' })

            })



            //Assigning input when changed
            .addCase(addResponseColumn.fulfilled, (state, action) => {

                //add to 1 row
                //add to header (every row)

                console.log("Action : ", action)

                if (action?.payload?.payload?.dontAddHeaderCol == true) {

                } else {
                    state.responseColumns.push({ text: '' })
                }

                if (action?.payload?.payload?.type == 'all') {

                    console.log("Adding columns to every row")


                    for (let i = 0; i < state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields.length; i++) {
                        const element = state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i];
                        state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i].responseColumns.push({ text: '', response: '' })
                    }



                }
                else {
                    console.log("Adding columns to new row : ", state.formBeingMade.sections.length - 1, action)
                    state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].responseColumns.push({ text: '', response: '' })

                }


            })


            //Assigning input when changed
            .addCase(addAnswerChoiceColumn.fulfilled, (state, action) => {
                console.log('Add answer choice column SENT PARAMS : ', action)
                if (action.payload.payload.dontAddHeaderCol == true) {

                } else {
                    state.answerChoices.push({ text: '' })

                }

                if (action.payload.payload.type == 'all') {

                    for (let i = 0; i < state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields.length; i++) {
                        const element = state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i];

                        state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i].answerChoices.push({ text: '' })
                    }
                }
                else {
                    console.log("Adding columns to new row : ", state.formBeingMade.sections.length - 1, action.payload.payload.fieldIndex)
                    state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].answerChoices.push({ text: '' })

                }

            })




            //Assigning input when changed
            .addCase(removeResponseColumn.rejected, (state, action) => {

                console.log("REJECTED : ", action)
            })



            //Assigning input when changed
            .addCase(removeResponseColumn.fulfilled, (state, action) => {
                console.log("Paylaod :",)

                if (action.payload.payload.data.length > 0) {
                    state.responseColumns = action.payload.payload.data
                    for (let i = 0; i < state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields.length; i++) {
                        state.formBeingMade.sections[state.formBeingMade.sections.length - 1].fields[i].responseColumns.splice(action.payload.payload.columnIndex, 1)
                    }
                }
                else {
                    state.formBeingMade.sections[action.payload.payload.columnIndex.sectionIndex].fields[action.payload.payload.columnIndex.fieldIndex].responseColumns.splice(action.payload.payload.columnIndex.columnIndex, 1)

                }

            })


            //Assigning input when changed
            .addCase(removeAnswerChoice.fulfilled, (state, action) => {


                console.log("Action : ", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].answerChoices.splice(action.payload.payload.columnIndex - 1, 1)


            })

            //Assigning input when changed
            .addCase(deleteUserResponse.fulfilled, (state, action) => {

                console.log("removing user response:", action.payload.payload)
                //Does not remove correct index, removes last element every time - TODO
                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse.splice(action.payload.payload.columnIndex - 1, 1)
            })

            //Selecting Forms
            .addCase(SetForm.fulfilled, (state, action) => {
                state.loadedForm = action.payload.payload
                state.loadingForms = 'Done';
                state.FormsError = ''

            })



            //Creating Forms
            .addCase(removeColumnDuringCreation.fulfilled, (state, action) => {

                console.log("Removing/setting columns to be blank : ", action)



                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse.splice(action.payload.payload.columnIndex, 1)

                state.FormsError = ''
            })


            //Creating Forms
            .addCase(addColumnDuringCreation.fulfilled, (state, action) => {
                console.log("addColumnDuringCreation :  ", action)

                state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse.push({ question: '', answer: '' })

                state.FormsError = ''
            })



            //Creating Forms
            .addCase(addNewForm.fulfilled, (state, action) => {
                state.loadingForms = false;
                //  state.Forms.push(action.payload) TODO ideally Forms are updated here, but couldnt get it working so going to call GET again
                state.FormsError = ''
            })


            //Deleting Forms
            .addCase(deleteForm.rejected, (state, action) => {
                console.log("Error :", action.error.message)
                state.loadingForms = false;
                state.FormsError = action.error.message
            })
            .addCase(deleteForm.pending, (state, action) => {
                console.log("whats happening :", action)

                state.loadingForms = true;
                state.FormsError = ''
            })
            .addCase(deleteForm.fulfilled, (state, action) => {
                console.log("whats happening :", action)
                state.loadingForms = false; //by setting false here, the component will call fetch to update data
                state.FormsError = ''
            })
    },
});


export const loadForms = () => {
    return dispatch(fetchForms())
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectForms = (state) => state.forms;
export const getFormLoadStatus = (state) => state.forms.loadingForms;
export const getFormsError = (state) => state.forms.FormsError;
export const getLoadedForm = (state) => state.forms.loadedForm;
export const getRowsForDeletion = (state) => state.forms.rowsSelectedForDeletion;
export const getReportingSections = (state) => state.forms.sectionsReportingToUser;

export const getTrackedTasks = (state) => state.forms.trackedTasks;

export default Formslice.reducer;
export const itemActions = Formslice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.forms =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/